import React, { useState } from "react";

import { DataGrid, GridPreferencePanelsValue } from '@material-ui/data-grid';
import apiService from "../../services/api";
import { Badge, Box, Button, FormControlLabel, Switch } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FilterListIcon from '@material-ui/icons/FilterList';
import { navigate } from "gatsby-link";

export default function AppDataGrid({
  api,
  linksPath,
  createLink,
  viewLink,
  showFilterActive,
  showFilterIcon,
  onRowSelected,
  columns,
  showButtonNew = true,
  toolbar = <></>,
  beforeDoSearch,
  refresh,
}) {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [activeData, setActiveData] = useState(true);

  const [openPreferencePanel, setOpenPreferencePanel] = useState(false);

  const onActiveChange = () => {
    setActiveData(!activeData);
  };

  const onStateChange = (e) => {
    if (e.state.preferencePanel.open !== openPreferencePanel) {
      setOpenPreferencePanel(e.state.preferencePanel.open);
    }
  };

  const onPageChange = (params) => {
    setPage(params.page);
  };

  const onSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
    }
  };

  const onFilterChange = (params) => {
    const items = [];

    params.filterModel.items.forEach(item => {
      if (!item.value) return;

      items.push(item);
    });

    if (JSON.stringify(items) !== JSON.stringify(filterValues)) {
      setFilterValues(items);
    }
  };

  const handleOnRowSelected = (row) => {
    if (onRowSelected) {
      onRowSelected(row);
    }

    if (linksPath || viewLink) {
      setLoading(true);
      navigate(viewLink ? `${viewLink}?id=${row.data.id}` : `${linksPath}/update?id=${row.data.id}`, {
        state: {
          modal: true
        }
      });

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const doSearch = () => {
    let active = true;

    (async () => {
      if (loading) return;

      setLoading(true);

      const params = {};
      params['page'] = page + 1;

      filterValues.forEach(filter => {
        if (!filter.value) return;

        params[filter.columnField] = filter.value;
        params[`_op_${filter.columnField}`] = filter.operatorValue;
      });

      sortModel.forEach(sort => {
        params[`_sort_${sort.field}`] = sort.sort;
      });

      if (!activeData) {
        params['_inactive'] = 1;
      }

      beforeDoSearch && beforeDoSearch(params);

      const response = await apiService.get(api, {
        params: params
      });
      const newRows = response.data.data;

      // if (!active) {
      //   return;
      // }

      setRowCount(response.data.total);
      setPageSize(response.data.per_page);
      setRows(newRows);
      setLoading(false);
    })();

    // return () => {
    //   active = false;
    // };
  };

  React.useEffect(() => {
    return doSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterValues, sortModel, activeData, refresh]);

  return (
    <div>
      {(createLink || linksPath || showFilterActive || showFilterIcon) && (
        <Box display="flex" flexDirection="row-reverse" style={{ marginBottom: 10 }}>
          {showButtonNew && (createLink || linksPath) && (
            <Box p={1}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  navigate(createLink ?? `${linksPath}/create`, {
                    state: {
                      modal: true
                    }
                  });
                }}
              >
                Novo
              </Button>
            </Box>
          )}
          {showFilterActive && (
            <Box p={1}>
              <FormControlLabel
                control={<Switch checked={activeData} onChange={onActiveChange} color="primary" />}
                label="Ativos"
              />
            </Box>
          )}

          {toolbar}

          {showFilterIcon && (
            <Box p={1}>
              <Button
                variant="text"
                color="primary"
                startIcon={
                  <Badge style={{ zIndex: 0 }} badgeContent={filterValues.length} color="secondary">
                    <FilterListIcon />
                  </Badge>
                }
                onClick={() => { setOpenPreferencePanel(!openPreferencePanel) }}
              >
                Filtrar
            </Button>
            </Box>
          )}
        </Box>
      )}

      <div style={{ height: 500, width: '100%', background: '#fff' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowCount={rowCount}
          paginationMode="server"
          onPageChange={onPageChange}
          onFilterModelChange={onFilterChange}
          onSortModelChange={onSortModelChange}
          loading={loading}
          onRowSelected={handleOnRowSelected}
          state={{
            preferencePanel: {
              open: openPreferencePanel,
              openedPanelValue: GridPreferencePanelsValue.filters,
            },
          }}
          onStateChange={onStateChange}
          localeText={{
            filterOperatorAfter: 'Depois',
            filterOperatorBefore: 'Antes',
            filterOperatorEndsWith: 'Termina com',
            filterOperatorContains: 'Contém',
            filterOperatorEquals: 'Igual',
            filterOperatorOnOrAfter: 'Igual ou antes',
            filterOperatorOnOrBefore: 'Igual ou depois',
            filterOperatorIs: 'É',
            filterOperatorNot: 'Não é',
            filterOperatorStartsWith: 'Começa com',
            filterPanelOperators: 'Operadores',
            filterPanelColumns: 'Colunas',
            filterPanelInputLabel: 'Valor',
            filterPanelInputPlaceholder: 'Informe um valor',
            filterPanelDeleteIconLabel: 'Limpar',
          }}
          disableColumnMenu
          hideFooterSelectedRowCount
          disableDensitySelector
          disableColumnSelector
          pagination
        />
      </div>
    </div>
  )
}
