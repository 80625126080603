import { Box, Container, LinearProgress, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AppDataGrid from "../../components/data/app-data-grid";
import GeneralErrors from "../../components/form/general-errors";
import LayoutLogged from "../../components/layout/logged";
import api from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import Statements from "../../components/dialog/statements/statements";
import { ifNotHaveAccessRedirect } from "../../services/auth";

registerLocale("pt-BR", ptBr);

const CustomDatePicker = styled(DatePicker)`
  width: 90px;
  border: 0;
  border-bottom: solid #9e9e9e 1px;
  height: 1.1876em;
  font: inherit;
  font-size: 15px;
  padding: 10px 0;
  background: #f8f8fa;
`;

const useStyles = makeStyles((theme) => ({
  resume: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: theme.spacing(16),
      padding: 10
    },
  },
  title: {
    fontSize: 12,
    color: 'gray',
  },
  amount: {
    fontSize: 24,
    textAlign: 'center',
    paddingTop: 30
  },
  link: {
    textDecoration: 'none',
    color: 'blue',
    cursor: 'pointer',
  },
  linkSelected: {
    color: 'green',
  }
}));

export default function PointsIndex() {
  const classes = useStyles();
  const [resume, setResume] = useState();
  const [errors, setErrors] = useState();
  const [query, setQuery] = useState('');
  const [params, setParams] = useState();
  const [refresh, doRefresh] = useState(0);
  const [expireAt, setExpireAt] = useState(new Date());
  const [openStatements, setOpenStatements] = useState(false);
  const [userSelected, setUserSelected] = useState();

  const changeQuery = (q) => {
    if (q === query) {
      setQuery('');
    } else {
      setQuery(q);
    }

    doRefresh(refresh + 1);
  };

  useEffect(() => {
    (async () => {
      if (!params) return;

      try {
        const response = await api.get(`/statement/points/resume`, {
          params: { ...params, ...{ expire_at: expireAt } }
        });
        setResume(response.data);
      } catch (e) {
        setErrors(e?.response?.data || 'Erro desconhecido');
      }
    })();
  }, [params]);

  useEffect(() => {
    doRefresh(refresh + 1);
  }, [expireAt]);

  if (ifNotHaveAccessRedirect('statement.write')) return <></>;

  return (
    <LayoutLogged title="Sistema de pontuação">
      <Container>
        {errors && <GeneralErrors errors={errors} />}

        {!resume ? <LinearProgress /> : (
          <>
            <div className={classes.resume}>
              <Paper>
                <span className={classes.title}>PONTOS APROVADOS</span>
                <div className={classes.amount}>
                  <span onClick={() => changeQuery('approved')} className={`${classes.link}` + (query === 'approved' ? ` ${classes.linkSelected}` : '')}>{new Intl.NumberFormat('pt-BR').format(resume?.approved)}</span>
                </div>
              </Paper>

              <Paper>
                <span className={classes.title}>AGUARDANDO CONFERÊNCIA</span>
                <div className={classes.amount}>
                  <span onClick={() => changeQuery('pending')} className={`${classes.link}` + (query === 'pending' ? ` ${classes.linkSelected}` : '')}>{new Intl.NumberFormat('pt-BR').format(resume?.pending)}</span>
                </div>
              </Paper>
            </div>
          </>
        )}

        {openStatements && expireAt && (
          <Statements
            open={openStatements}
            onClose={() => setOpenStatements(false)}
            apiStatements={`/statement/user/${userSelected}/points`}
            defaultDate={expireAt}
            setFirstDayOfMonth={false}
          />
        )}

        {query !== undefined && (
          <AppDataGrid
            api={`/statement/points?q=${query}&expire_at=${expireAt.toISOString()}`}
            onRowSelected={(row) => {
              setUserSelected(row.data.id);
              setOpenStatements('points');
            }}
            showButtonNew={false}
            refresh={refresh}
            beforeDoSearch={params => {
              delete params.page;
              setParams(params);
            }}
            toolbar={
              <Box p={1} style={{ display: 'flex' }}>
                <CustomDatePicker
                  selected={expireAt}
                  onChange={(value) => setExpireAt(value)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/aaaa"
                  locale="pt-BR"
                />
              </Box>
            }
            columns={[
              { field: 'name', type: 'string', headerName: 'Nome', width: 400 },
              { field: 'organization_name', type: 'string', headerName: 'Organização', width: 200 },
              { field: 'points', type: 'number', headerName: 'Pontos', width: 150 },
            ]}
            showFilterIcon
          />
        )}
      </Container>
    </LayoutLogged>
  );
}